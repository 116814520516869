@keyframes biggerPulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__animated.animate__pulse {
  animation-name: biggerPulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 2;
}

.Footer {
	background-color: #283036;
	font-family: 'DM Serif Display';
	color: #ffffff;
	font-weight: 100;
	padding-bottom: 40px;
	padding-top: 20px;
	text-align: center;

	.container {
		margin: auto;
		max-width: 1040px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-wrap: wrap;
	}

	h1 {
		color: #E3B155;
		font-size: 2.5rem;
	}

	ul {
		list-style-type: none;
		padding-left: 0;

		li {
			margin-top: 15px;

			&:hover {
				transform: scale(1.05);
			}
		}

		a {
			font-size: 1.25rem;
			text-decoration: none;
			color: #ffffff;
		}
	}

	p {
		margin: 4px;
		font-size: 1.25rem;
	}

	.icons {
		font-size: 2.5rem;
		display: flex;

		a {
			color: #ffffff;

			&:hover {
				transform: scale(1.05);
			}
		}

		svg {
			margin-right: 5px;
			margin-left: 5px;
			cursor: pointer;
		}
	}
}