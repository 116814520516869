.Publications {
	margin-top: 80px;
	margin-bottom: 20px;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 80px;

	// Phone only
	// @media (max-width: 599px) {}

	// Desktop
	@media (min-width: 1200px) {
		max-width: 1040px;
		margin-left: auto;
		margin-right: auto;
	}
}