.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.Modal-Content {
    position: absolute;
    margin: auto;
    top: 40;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    border: 0;
    borderRadius: 0;
    background-color: #c2c3c7;
    color: #323646;
    font-weight: 500;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
}

.Modal-Header {
    display: flex;
    color: #eeffff;
    height: 120px;
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    width: 100%;

    &.green-mint {
        background-color: darken(#19d2c5, 12%);
    }

    &.red {
        background-color: darken(#d6006d, 12%);
    }

    &.blue {
        background-color: darken(#003893, 12%);
    }

    &.brown-red {
        background-color: #985E6D;
    }

    .text {
        flex-grow: 1;

        h1 {
            font-size: 1.8em;
            font-family: 'DM Serif Display';
            margin: 0;
            font-weight: 100;
        }
    }

    .button {
        padding-right: 20px;

        p {
            margin-left: 10px;
            cursor: pointer;
            font-size: 1.5rem;
        }
    }
}

.Modal-Body {
    font-family: 'Montserrat';
    font-size: 20px;
    padding: 10px;
    margin-top: 120px;
    overflow-y: auto;

    h1 {
        font-size: 1.2em;
        font-weight: 500;
        text-align: left;
    }

    .text-center {
        text-align: center;
    }
}

// Desktop
@media (min-width: 1200px) {
    .Modal-Header,
    .Modal-Content {
        width: 800px;
    }
    .Modal-Content {
        align-self: center;
        max-height: 80%;
    }
    .Modal-Body {
        padding: 20px;
        text-align: justify;
    }
}

// Big Desktop
// @media (min-width: 1800px) {}
