.InfoCards {
	display: flex;
	padding-left: 20px;
	margin-bottom: 60px;
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */

  	&::-webkit-scrollbar {
  		display: none;
  	}

	// Phone only
	@media (max-width: 599px) {
		overflow-x: auto;
	}

	// Tablet Portrait {
  	//@media (min-width: 600px) { }

  	// Tablet Landscape {
  	//@media (min-width: 900px) { }

	// Desktop
	@media (min-width: 1200px) {
		align-self: center;
	}

	// Big Desktop
	// @media (min-width: 1800px) { }
}

