.App {
  .section {
    display:  flex;
    flex-direction: column;

    &.light {
      background-color: darken(#19d2c5, 12%);
    }

    &.dark {
      background-color: #323646;
    }

    &.bg-image {
      background-image: url('./images/matthias-background.jpeg');
      background-position: center;
      background-size: cover;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: rgba(0,0,0,0.2);
      }

      video {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
      }
    }
  }
}
