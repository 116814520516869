.Policy {
	.Container {
		margin-top: 80px;
		margin-bottom: 80px;
		padding-left: 10px;
		padding-right: 10px;
		font-family: 'Montserrat';
		font-weight: 500;

		.text-center {
			text-align: center;
		}

		.link-to-back {
			margin-top: 50px;
			text-align: center;
			cursor: pointer;

			a {
				text-decoration: none;
			}
		}
	}

	// Desktop
	@media (min-width: 1200px) {
		.Container {
			margin: 80px auto;
			max-width: 1040px;
			text-align: justify;
		}

		h1 {
			font-size: 1.3em;
		}
	}
}