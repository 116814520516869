.Questions {
	margin-top: 80px;
	margin-bottom: 20px;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 80px;

	&:active {
		transform: scale(1.05);
	}

	.Question-Container {
		cursor: pointer;

		.Question-Hover {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .5s ease;
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			text-align: center;
			background: rgba(0,0,0,0.5);
			width: 100%;
			height: 100%;

			.text {
				color: white;
				padding: 10px 20px;
				background-color: darken(#19d2c5, 12%);
				font-family: 'DM Serif Display';
			}
		}

		&:hover {
			.Question-Hover {
				opacity: 1;
			}
		}

		.Question-Image {
			height: 200px;
			background-position: center;
			background-size: cover;

			&.colombia-image {
				background-image: url('../../images/colombia.jpeg');
			}
		}

		.Question-Content {
			text-align: left;

			.Question-Title {
				font-family: 'DM Serif Display';
				padding-left: 10px;
				color: #eeffff;

				h1 {
					font-weight: 100;
					margin: 0;
				}

				&.green-mint {
			        background-color: darken(#19d2c5, 12%);
			    }

			    &.red {
			        background-color: darken(#d6006d, 12%);
			    }
			}

			.Question-Text {
				padding: 20px;
				font-family: 'Montserrat';
				font-size: 0.9em;
				background-color: #c2c3c7;

				p {
					margin: 0;
				}

				button {
					width: 100%;
					margin-top: 20px;
					color: white;
					padding: 10px 20px;
					background-color: darken(#19d2c5, 12%);
					font-family: 'DM Serif Display';
					border: none;
					font-size: 20px;
				}

				.cta-text {
					text-align: right;
					color: #985E6D;
					font-weight: 600;
					cursor: pointer;
					margin-top: 20px;

					&.green-mint {
				        color: #19d2c5;
				    }

				    &.red {
				        color: #d6006d;
				    }
				}
			}
		}
	}

	// Phone only
	@media (max-width: 599px) {
		.Question-Container.hidden-mobile {
			display: none;
		}
		.Question-Hover.hidden-mobile {
			display: none;
		}
		.Question-Video {
			height: 300px;
		}
	}

	// Desktop
	@media (min-width: 1200px) {
		max-width: 1040px;
		margin-left: auto;
		margin-right: auto;

		&:hover {
			transform: scale(1.05);
		}

		.Question-Container {
			display: flex;

			.Question-Image {
				width: 50%;
				height: auto;
			}

			.Question-Content {
				width: 50%;
			}

			&.hidden-desktop {
				display: none;
			}
		}

		button.hidden-desktop {
			display: none;
		}
	}
}