.Matthias {
	margin-top: 20px;
	padding-bottom: 80px;
	font-size: 20px;
	font-weight: 500;
	transition: transform .2s;

	&:active {
		transform: scale(1.05);
	}

	.Content-Container {
		button {
			width: 100%;
			margin-top: 20px;
			color: white;
			padding: 10px 20px;
			background-color: #d6006d;
			font-family: 'DM Serif Display';
			border: none;
			font-size: 20px;
		}

		.Middle {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .5s ease;
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			text-align: center;
			background: rgba(0,0,0,0.5);
			width: 100%;
			height: 100%;

			.text {
				color: white;
				padding: 10px 20px;
				background-color: #d6006d;
				font-family: 'DM Serif Display';
			}
		}

		&:hover {
			.Middle {
				opacity: 1;
			}
		}
	}

	.Matthias-Image {
		background-image: url('../../images/matthias-image.png');
	    background-position: 40% center;
	    background-size: cover;
		color: white;
		height: 400px;
	}

	.Matthias-Text {
		background-color: #985E6D;
		font-family: 'Montserrat';
		color: #ffffff;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 10px;
		padding-right: 10px;

		p {
			margin: 0;
		}
	}

	// Phone only
	@media (max-width: 599px) {
		.hidden-mobile {
			display: none;
		}
	}

	// Desktop
	@media (min-width: 1200px) {
		max-width: 1040px;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
		transition: transform .2s;

		&:hover {
			transform: scale(1.05);
		}

		.Info-Container {
			display: flex;

			.Matthias-Image {
				width: 50%;
				height: auto;
			}

			.left-content {
				width: 50%;

				h1 {
					font-size: 2.2em;
				}

				.Matthias-Text {
					text-align: justify;
					padding: 40px;
				}
			}
		}

		.hidden-desktop {
			display: none;
		}
	}
}