.LangSwitch {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	.opt {
		font-family: 'Montserrat';
		font-weight: regular;
		margin: 0 15px;
		color: #E3B155;
		cursor: pointer;
		text-align: center;

		p {
			margin: 0;
			font-size: 1.8em;
			line-height: 0.8em;

			&.flag {
				font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 
  'Segoe UI Emoji', 'Segoe UI Symbol';
			}
		}

		&.selected {
			p {
				font-size: 2.2em;
				font-weight: bold;
			}
		}
	}
}
