.Title {
	font-family: 'DM Serif Display';
	font-weight: 100;
	font-size: 1.8em;
	text-align: center;
	margin-bottom: 40px;
	margin-top: 40px;

	h1, p {
		margin: 0;

		&.dark {
			color: #27242AD9;
		}

		&.light {
			color: #FFFFFFcc;
		}
	}

	p {
		position: relative;
		top: -8px;
		font-family: 'Montserrat';
		font-size: smaller;
	}

	// Tablet Portrait {
  	//@media (min-width: 600px) { }

  	// Tablet Landscape {
  	//@media (min-width: 900px) { }

	// Desktop
	@media (min-width: 1200px) {
		font-size: 1.5em;
	}

	// Big Desktop
	@media (min-width: 1800px) {
		font-size: 1.8em;
	}
}