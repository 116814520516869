.VideoCard {
	color: #eeffff;
	font-family: 'DM Serif Display';
	width: 230px;
	margin-right: 20px;
	display: block;
	cursor: pointer;
	flex-shrink: 0;
	text-align: center;

	&:active {
		transform: scale(1.05);
	}

	&.selected {
		.Card-Header {
			background-color: #E3B155;
		}

		.Card-Footer {
			background-color: #E3B155;
		}
	}

	&.green-mint {
		.Card-Header {
			background-color: #19d2c5;
		}

		.Card-Footer {
			background-color: darken(#19d2c5, 12%);
		}
	}

	&.red {
		.Card-Header {
			background-color: #d6006d;
		}

		.Card-Footer {
			background-color: darken(#d6006d, 12%);
		}
	}

	.Card-Header {
		text-align: left;
		padding-left: 10px;

		h1 {
			position: relative;
			font-size: 72px;
			line-height: 0.8;
			margin: 0;
			position: relative;
			top: -2px;
		}
	}

	.VideoContainer {
		width: 100%;
		height: 250px;
		position: relative;
		overflow: hidden;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		video {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.Card-Footer {
		min-height: 54px;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			margin: 0;
			font-size: 1.2em;
		}
	}

	// Desktop
	@media (min-width: 1200px) {
		&:hover {
			transform: scale(1.05);
		}
	}
}