.VideoBanner {
	background-color: #323646;
	width: 100%;
	height: 760px;
	position: relative;
  	overflow: hidden;
  	text-align: center;
  	align-items: center;
  	justify-content: center;
	display: flex;
    flex-direction: column;
	color: white;
	text-shadow: 1px 1px #000000;
	text-align: center;

	.overlay {
	  position: absolute;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  z-index: 2;
	  background: rgba(0,0,0,0.2);
	}

	video {
	  object-fit: cover;
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	}

	.Content {
		position: relative;
		z-index: 3;
		animation-name: opacityOn;
		animation-duration: 3s;
		animation-timing-function: normal;
		animation-delay: 0.6s;
		animation-iteration-count: 1;
		animation-direction: forwards;
		animation-fill-mode: backwards;
	}

	@keyframes opacityOn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	h1 {
		text-transform: uppercase;
		font-family: 'DM Serif Display';
		font-weight: 100;
		font-size: 4em;
		margin: 0;
	}

	p {
		font-family: 'Montserrat';
		font-weight: 500;
		margin: 0;
		font-size: 2.5em;
	}

	img {
		height: 150px;
	}

	// Tablet Portrait {
  	//@media (min-width: 600px) { }

  	// Tablet Landscape {
  	//@media (min-width: 900px) { }

	// Desktop
	@media (min-width: 1200px) {
		height: 650px;

		h1 {
			font-size: 5em;
		}

		p {
			font-size: 4em;
		}

		img {
			height: 180px;
		}
	}

	// Big Desktop
	@media (min-width: 1800px) {
		height: 930px;

		h1 {
			font-size: 6em;
		}

		p {
			font-size: 5em;
		}

		img {
			height: 230px;
		}
	}
}


