.PublicationCard {
  display: flex;
  background-color: #c2c3c7;
  cursor: pointer;
  margin-bottom: 40px;

  .image-container {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .content {
    padding: 10px;

    p {
      margin: 0;
      font-family: 'Montserrat';

      &.site-name {
        font-family: 'DM Serif Display';
        font-size: 1em;
        color: #d6006d;
        text-align: left;
      }

      &.title {
        font-family: 'DM Serif Display';
        font-size: 1.2em;
      }

      &.description {
        font-size: smaller;
      }

      &.link {
        font-size: smaller;
        text-align: right;
        font-family: 'DM Serif Display';

        a {
          text-decoration: none;
        }
      }
    }
  }

  // Phone only
  @media (max-width: 599px) {
    flex-direction: column;
  }

  // Desktop
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.05);
    }

    .image-container {
      width: 230px;
      flex-shrink: 0;
      display: flex;
    }
  }
}