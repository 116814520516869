.Divider {
	background-color: red;
	width: calc(50% + 70px);
	margin-bottom: 10px;
	border-bottom: solid 2px #000000;

	&.right {
		align-self: flex-end;
	}

	&.left {
		align-self: flex-start;
	}

	&.green-mint {
		border-color: #19d2c5;
	}

	&.red-wine {
		border-color: #d6006d;
	}

	// Desktop
	@media (min-width: 1200px) {
		width: calc(50% + 90px);
	}

	// Big Desktop
	@media (min-width: 1800px) {
		width: calc(50% + 105px);
	}
}