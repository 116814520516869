.Intro {
	display: flex;
	flex-direction: column;
	align-self: center;
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'Montserrat';
	padding: 10px;
	color: #FFFFFF;
	font-weight: 500;

	h1 {
		font-size: 1.6em;
		color: #d6006d;
		text-align: left;
	}

	p {
		font-size: 20px;
	}

	// Desktop
	@media (min-width: 1200px) {
		.Container {
			max-width: 1040px;
			text-align: justify;
		}

		h1 {
			font-size: 1.3em;
		}
	}
}